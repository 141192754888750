<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate(),
         'pb-5': !(Object.keys($store.getters.getUserProfile).length > 0 && isAdmin)}">
      <div class="admin-edit" @click="editTranslate([
          SYSTEM_ROLES.SYSTEM_ROLE_DEVELOPER.translation,
          SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.translation,
          SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.translation,
          SYSTEM_ROLES.SYSTEM_ROLE_USER.translation,
          SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.translation,
          'manageUsers_groups',
        ])"></div>
      <div class="content-tabs content-tabs--separator"
           v-if="Object.keys($store.getters.getUserProfile).length > 0 && isAdmin"
      >
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               v-bind:class="{active: item.active}"
               :key="index"
               v-if="item.visible"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              <template>
                {{item.label}}
              </template>
            </div>
          </div>
        </template>
      </div>
      <div class="content-top-line-wrap">
        <ToggleFilterButton
            v-if="!navTabs.groups.active"
                :count="countFilterParams"
                :showFilter="showFilter"
                @toggleFilter="$emit('toggleFilter')"
        />
      </div>
    </div>

    <div class="light-bg-block"
         v-if="!(isAdmin && navTabs[SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.name].active) || isDeveloper">
      <div class="btn-left-block">
        <div class="btn-left-block-i"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_selectAll'])"></span>
          <DefaultCheckbox
              v-if="!navTabs.groups.active && _.has(currentPermissions, PERMISSIONS.CAN_MANAGE_ALL_USER_PERMISSIONS)"
              :label="$t('common_selectAll.localization_value.value')"
              @input="(val) => $emit('selectAll', val)"
          />
        </div>
      </div>

      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['manageUsers_AddNew', 'userModule_BlockUser',])"></div>

        <div class="content-top-line-wrap ml-2" style="position: relative;"
             v-if="!navTabs.groups.active && _.has(currentPermissions, PERMISSIONS.CAN_MANAGE_ALL_USER_PERMISSIONS)"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <TableGeadButton
              :value="$t('userModule_BlockUser.localization_value.value')"
              :ico="'warning'"
              @click.native="$emit('massBlock')"
          />
        </div>

        <template v-if="!(isSupperAdmin && navTabs[SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.name].active)">
          <router-link v-if="!navTabs.groups.active" :to="$store.getters.GET_PATHS.mainSettingsManageUsersLinkCreate + getCreateUrl">
            <MainButton class="btn-fit-content ml-3"
                        :value="$t(`manageUsers_AddNew.localization_value.value`)"
                        :ico="'ico'"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus"/>
              </template>
            </MainButton>
          </router-link>
        </template>
        <router-link v-if="navTabs.groups.active" :to="$store.getters.GET_PATHS.mainSettingsManageUsersGroupLinkCreate">
          <MainButton class="btn-fit-content ml-3"
                      :value="$t(`manageUsers_AddNew.localization_value.value`)"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>

      </div>
    </div>

  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import {SYSTEM_ROLES} from "@/staticData/staticVariables";
  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TableGeadButton from "@/components/coreComponents/TableComponents/TableHeadButton/TableHeadButton";


  export default {
    name: "ManageUsersHead",
    components: {
      TableGeadButton,
      DefaultCheckbox,
      ToggleFilterButton,
      MainButton,
      PlusIconSVG,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    computed: {
      getCreateUrl(){
        return `?type=${this._.find(this.navTabs, {active: true})?.name}`
      }
    },

    data(){
      return {
        SYSTEM_ROLES: SYSTEM_ROLES,
      }
    },

    methods: {

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .light-bg-block{
    display: flex;
    flex-wrap: wrap;
  }

  @include for-992{
    .btn-left-block {
      margin-bottom: 15px;
    }

    .btn-right-block{
      flex-wrap: wrap;
    }

    .main-button{
      margin-bottom: 10px;
    }

  }

</style>

