<template>
  <div class="content-top-line-btn">
    <div class="table-head-btn"
         :class="{ 'print' : ico === 'print', 'import' : ico === 'import', 'question' : ico === 'question', 'warning' : ico === 'warning'}"
    >
      <div class="table-head-btn__btn btn-style">
        {{value}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TableGeadButton",

    props: [
      'showFilter',
      'value',
      'ico'
    ],
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .table-head-btn{

    &__btn{
      position: relative;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $brown;
      /*padding-left: 30px;*/
      padding-left: 25px;
      padding-right: 20px;


      @include for-1200 {
        /*font-size: 0!important;*/
        /*padding-right: 0;*/
        font-size: 14px;
        padding-left: 21px;
        padding-right: 5px;

        &:after{
          content: '';
          width: 40px;
          height: 40px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      @include for-550 {
        font-size: 14px;
        padding-left: 16px;
      }

      &:before{
        content: "";
        position: absolute;
        top: 1px;
        left: 0;
        display: block;
        width: 16px;
        height: 16px;

        @include for-1200 {
          /*left: 50%;
          transform: translateX(-50%);*/
        }

        @include for-550 {
          width: 13px;
          height: 13px;
        }
      }

    }

    &.print &__btn:before{
      background: url("../../../../assets/img/common/printIco.svg") center/contain no-repeat;
    }

    &.import &__btn:before{
      background: url("../../../../assets/img/common/importIco.svg") center/contain no-repeat;
    }

    &.question &__btn:before{
      background: url("../../../../assets/img/common/questIco.svg") center/contain no-repeat;
    }

    &.warning &__btn:before{
      width: 20px;
      height: 15px;
      background: url("../../../../assets/img/common/warning-icon.svg") center/contain no-repeat;
    }

  }
</style>
