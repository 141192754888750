<template>
  <div class="fragment">
    <ManageUsersTableUser
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            :navTabs="navTabs"
            @changeTab="changeTab"
            @resetFilter="resetFilter"
            @showMore="showMore"
            @changeFilter="changeFilter"
            @downloadFiles="downloadFiles"
            @reload="reload"
            @massBlock="massBlock"
    />
  </div>
</template>

<script>
  import ManageUsersTableUser from "./ManageUsersTableSection/ManageUsersTableSection";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {SYSTEM_ROLES} from "../../../../../staticData/staticVariables";

  export default {
    name: "ManageUsersTable",
    components: {
      ManageUsersTableUser,
    },

    mixins: [routeFilter],

    watch: {
      loadUserRoleAndAuthorizedData() {
        this.getRolesByTabs()

      }
    },

    data(){
      return {
        forPage: this.$store.getters.getManageUsersForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterUserContactName: '',
        filterUserEmail: '',
        filterType: '',
        filterCountryId: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          [SYSTEM_ROLES.SYSTEM_ROLE_DEVELOPER.name]: {
            active: false,
            visible: false,
            label: this.$t(`${SYSTEM_ROLES.SYSTEM_ROLE_DEVELOPER.translation}.localization_value.value`),
            name: SYSTEM_ROLES.SYSTEM_ROLE_DEVELOPER.name,
            id: SYSTEM_ROLES.SYSTEM_ROLE_DEVELOPER.id
          },
          [SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.name]: {
            active: false,
            visible: false,
            label: this.$t(`${SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.translation}.localization_value.value`),
            name: SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.name,
            id: SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.id
          },
          [SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.name]: {
            active: false,
            visible: false,
            label: this.$t(`${SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.translation}.localization_value.value`),
            name: SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.name,
            id: SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.id
          },
          // [SYSTEM_ROLES.SYSTEM_ROLE_USER.name]: {
          //   active: false,
          //   visible: false,
          //   label: this.$t(`${SYSTEM_ROLES.SYSTEM_ROLE_USER.translation}.localization_value.value`),
          //   name: SYSTEM_ROLES.SYSTEM_ROLE_USER.name,
          //   id: SYSTEM_ROLES.SYSTEM_ROLE_USER.id
          // },
          [SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.name]: {
            active: false,
            visible: false,
            label: this.$t(`${SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.translation}.localization_value.value`),
            name: SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.name,
            id: SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.id
          },
          groups: {
            active: false,
            visible: this.isAdmin,
            label: this.$t(`manageUsers_groups.localization_value.value`),
            name: 'groups',
            id: 999
          }
        },

        accessRoles: []
      }
    },

    // USER_MANAGE_ACCESS_SUB_ADMIN
    // USER_MANAGE_ACCESS_SUB_USER

    mounted() {

      this.$store.dispatch('getSystemRoles').then(response => {
        this.accessRoles = this.getRespPaginateData(response)
        if(this.loadUserRoleAndAuthorizedData){
          this.getRolesByTabs()
        }
      })

    },

    methods: {

      getRolesByTabs() {
        if(this.$store.getters.getCurrentUserRights?.role?.id === SYSTEM_ROLES.SYSTEM_ROLE_DEVELOPER.id){
          this.navTabs[SYSTEM_ROLES.SYSTEM_ROLE_USER.name] = {
            active: false,
            visible: false,
            label: this.$t(`${SYSTEM_ROLES.SYSTEM_ROLE_USER.translation}.localization_value.value`),
            name: SYSTEM_ROLES.SYSTEM_ROLE_USER.name,
            id: SYSTEM_ROLES.SYSTEM_ROLE_USER.id
          }
        }

        if (this.isAdmin) {
          this.navTabs.groups.visible = true
        }

        for(let tab in this.navTabs){
          if(this._.find(this.accessRoles, {id: this.navTabs[tab].id})){
            this.navTabs[tab].visible = true
          }
        }

        if(this.$route.query.type && this._.has(this.navTabs, this.$route.query.type)){
          let tab = this.navTabs[this.$route.query.type]

          this.skipRadioChecked(this.navTabs, 'active')
          tab.active = true
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          userContactName: this.$route.query.userContactName,
          userEmail: this.$route.query.userEmail,
          type: this.$route.query.type,
          countryId: this.$route.query.countryId,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if(!this.checkHasAccess()){
          let availableTab = this._.find(this.navTabs, {visible: true})
          if(availableTab){
            return this.changeTab(availableTab.name)
          }
          return
        }

        if (this.$route.query.type !== 'groups') {
          if (next) this.$store.commit('setNextManageUsersPage', true)

          // save filter params in store
          this.$store.commit('setManageUsersFilter', window.location.search)

          this.$store.dispatch('fetchManageUsers', url).then(() => {
            this.$store.commit('setNextManageUsersPage', false)
            this.$store.commit('setGlobalUpdateTable', false)
          })
        }
        else {
          if (next) this.$store.commit('setNextManageUsersGroupsPage', true)

          // save filter params in store
          this.$store.commit('setManageUsersGroupsFilter', window.location.search)

          this.$store.dispatch('fetchManageUsersGroups', url).then(() => {
            this.$store.commit('setNextManageUsersGroupsPage', false)
            this.$store.commit('setGlobalUpdateTable', false)
          })
        }


        this.checkCountFilter(['userName','type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        // let activeTab = this._.find(this.navTabs, {active: true})
        let params = {
          filterId: 'id',
          filterUserId: 'UserId',
          filterUserContactName: 'userPersonalContact.name',
          filterUserEmail: 'UserEmail',
        }

        if (!this.navTabs.groups.active) {
          params['filterType'] = 'byRoleNames'
        }

        if (this.isAdmin && this.navTabs[SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.name].active) {
          params['filterCountryId'] = 'byCountry'
        }

        this.generateFilterQueryParams(
          myQuery,
          params,
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

      checkHasAccess(){
        let activeTab = this._.find(this.navTabs, {active: true})
        if(!activeTab){
          return false
        }

        if(this._.find(this.accessRoles, {id: activeTab.id})){
          return true
        }

        if (this.isAdmin && activeTab.name === 'groups') {
          return true
        }

        return false
      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.exportTableFiles(url, type, 'getExportManageUsers', 'AdminPermissions')

      },

      massBlock() {
        let usersIds

        usersIds = this.getCheckedRows('row-name')

        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          let data = {
            ids: usersIds
          }

          this.$store.dispatch('massBlockUser', data).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.reload()
              this.openNotify('success', 'common_notificationRecordChanged')

            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)

      },

    },

  }
</script>

<style scoped>

</style>
