
<template>
  <div class="fragment">
    <template v-if="$store.getters.getManageUsers.length > 0 && $store.getters.getManageUsersLoading !== true">
      <div class="custom-row unidentified-payments-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getManageUsers"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">

              <div class="case-cart__head">
                <div class="case-cart__title case-cart__title--status">

                  <DefaultCheckbox
                      v-if="_.has(currentPermissions, PERMISSIONS.CAN_MANAGE_ALL_USER_PERMISSIONS) && !navTabs[SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.name].active"
                      class="empty-label mb-2"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
                  />
                  <span class="cursor-initial">
                    {{item.email}}
                  </span>
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['manageUsers_Contacts'])"></div>
                      {{$t('manageUsers_Contacts.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      <ValueHelper
                          :value="item"
                          :deep="'user_personal_contact.phone'"
                      />
                      <ValueHelper
                          :value="item"
                          :deep="'user_personal_contact.email'"
                      />
                    </div>
                  </div>
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['manageUsers_Name'])"></div>
                      {{$t('manageUsers_Name.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      <ValueHelper
                          :value="item"
                          :deep="'user_personal_contact.user_full_name'"
                      />
                    </div>
                  </div>
<!--                  <div class="case-cart__col custom-col">-->
<!--                    <div class="case-cart__label">-->
<!--                      Pages Available-->
<!--                    </div>-->
<!--                    <div class="case-cart__content">-->
<!--                      Shipment, Orders FBM, Products, Orders TNT-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      v-if="isAdmin"
                      class="secondary-brown"
                      :value="item.locked ? $t('userModule_BlockedUser.localization_value.value') : $t('userModule_BlockUser.localization_value.value')"
                      :typeIco="'delete-brown'"
                      @click.native="blockUser(item.id, item.locked)"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn ml-auto">
                  <router-link
                      v-if="(!(isAdmin && navTabs[SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.name].active) && !(isSupperAdmin && navTabs[SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.name].active)) || isDeveloper"
                      :to="$store.getters.GET_PATHS.mainSettingsManageUsersLink + '/' + item.id">
                    <CaseCartButton
                            :value="$t('common_edit.localization_value.value')"
                            :ico="true"
                            :typeIco="'edit'"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getManageUsersCommonList.next_page_url !== null && $store.getters.getManageUsers.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextManageUsersPage}"
                  :count="$store.getters.getManageUsersCommonList.total - $store.getters.getManageUsersForPage < $store.getters.getManageUsersForPage ?
                  $store.getters.getManageUsersCommonList.total - $store.getters.getManageUsersForPage:
                  $store.getters.getManageUsersForPage"
          />
          <ExportBtn
              v-if="(isAdmin && navTabs[SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.name] && navTabs[SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.name].active)"
              @downloadFiles="type => $emit('downloadFiles', type)"
              class="table-bottom-btn__right"/>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getManageUsersLoading === false && $store.getters.getManageUsers.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import {SYSTEM_ROLES} from "@/staticData/staticVariables";
  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "ManageUsersTable",
    components: {
      DefaultCheckbox,
      ValueHelper,
      NoResult,
      ShowMore,
      ExportBtn,
      CaseCartButton,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      selectedNow: Boolean,
    },

    data(){
      return{
        SYSTEM_ROLES: SYSTEM_ROLES,
      }
    },

    methods: {

      blockUser(id, block) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          let data = {
            locked: block ? 0 : 1
          }

          this.$store.dispatch('blockUser', {id, data}).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$emit('reload')
              this.openNotify('success', 'common_notificationRecordChanged')

            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      removeUser(id){
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteManageUsers', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$emit('reload')
              this.openNotify('success', 'common_notificationRecordDeleted')

            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },
    }
  }
</script>

<style scoped>
  .unidentified-payments-table{
    padding-top: 24px;
  }
</style>
