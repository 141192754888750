
<template>
  <div class="fragment">
    <template v-if="$store.getters.getManageUsersGroups.length > 0 && $store.getters.getManageUsersGroupsLoading !== true">
      <div class="custom-row unidentified-payments-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getManageUsersGroups"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">

              <div class="case-cart__head">
                <div class="case-cart__title case-cart__title--status">
                  <span class="cursor-initial">
                    {{item.name}}
                  </span>
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
<!--                  <div class="case-cart__col custom-col">-->
<!--                    <div class="case-cart__label"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate(['manageUsers_Contacts'])"></div>-->
<!--                      Id-->
<!--                    </div>-->
<!--                    <div class="case-cart__content">-->
<!--                      <ValueHelper-->
<!--                          :value="item"-->
<!--                          :deep="'id'"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="$t('common_delete.localization_value.value')"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="removeUserGroup(item.id)"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn ml-auto">
                  <router-link
                      v-if="isAdmin || isDeveloper"
                      :to="$store.getters.GET_PATHS.mainSettingsManageUsersGroupLink + '/' + item.id">
                    <CaseCartButton
                            :value="$t('common_edit.localization_value.value')"
                            :ico="true"
                            :typeIco="'edit'"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getManageUsersGroupsCommonList.next_page_url !== null && $store.getters.getManageUsersGroups.length > 0"
                  @click.native="$emit('showMore')"
                  :count="$store.getters.getManageUsersGroupsCommonList.total - $store.getters.getManageUsersGroupsForPage < $store.getters.getManageUsersGroupsForPage ?
                  $store.getters.getManageUsersGroupsCommonList.total - $store.getters.getManageUsersGroupsForPage:
                  $store.getters.getManageUsersGroupsForPage"
          />
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getManageUsersGroupsLoading === false && $store.getters.getManageUsersGroups.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  // import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import {SYSTEM_ROLES} from "@/staticData/staticVariables";


  export default {
    name: "ManageUsersGroupsTable",
    components: {
      // ValueHelper,
      NoResult,
      ShowMore,
      CaseCartButton,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
    },

    data(){
      return{
        SYSTEM_ROLES: SYSTEM_ROLES,
      }
    },

    methods: {
      removeUserGroup(id){
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteManageUsersGroups', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$emit('reload')
              this.openNotify('success', 'common_notificationRecordDeleted')

            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },
    }
  }
</script>

<style scoped>
  .unidentified-payments-table{
    padding-top: 24px;
  }
</style>
