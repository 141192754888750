
<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-if="isAdmin && !navTabs[SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.name].active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>
        <SearchSelect
                :options="options"
                :label="$t('common_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearchUserFilter"
                @change="changeUsersFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
        />
      </div>
      <div class="table-filter__item"
           v-if="isAdmin && navTabs[SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.name].active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_country'])"></span>
        <DefaultSelect
            :options="countries"
            :optionsLabel="'name'"
            :label="$t('common_country.localization_value.value')"
            :selected="getCountrySelected()"
            @change="(val) => {setCountry(val)}"
        />
      </div>
      <div class="table-filter__item"
           v-if="navTabs[SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.name].active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_name'])"></span>
        <DefaultInput
            :label="$t('common_name.localization_value.value')"
            :type="'text'"
            v-model="userContactName"
        />
      </div>
      <div class="table-filter__item"
           v-if="navTabs[SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.name].active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_email'])"></span>
        <DefaultInput
            :label="$t('common_email.localization_value.value')"
            :type="'text'"
            v-model="userEmail"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  import {SYSTEM_ROLES} from "../../../../../../../staticData/staticVariables";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "ManageUsersFilter",
    components: {
      DefaultSelect,
      DefaultInput,
      SearchSelect,
      FilterBlock,
      MainButton,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
      navTabs: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userContactName: this.filterGetParams.userContactName ? this.filterGetParams.userContactName : '',
        userEmail: this.filterGetParams.userEmail ? this.filterGetParams.userEmail : '',
        countryId: this.filterGetParams.countryId ? this.filterGetParams.countryId : '',

        filterCounts: [
          'userId',
          'userName',
          'userContactName',
          'userEmail',
          'countryId',
        ],

        options: [],
        countries: [],

        SYSTEM_ROLES: SYSTEM_ROLES,
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userContactName = newVal.userContactName ? newVal.userContactName : ''
        this.userEmail = newVal.userEmail ? newVal.userEmail : ''
        this.countryId = newVal.countryId ? newVal.countryId : ''
      },

    },

    mounted() {
      if (this.isAdmin) {
        this.$store.dispatch('getManageUsersCreate').then(response => {
          this.countries = this.getRespData(response)?.countries || []
          this.getCountrySelected()
        })
      }
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      setCountry(val) {
        this.countryId = val.id
      },

      getCountrySelected(){
        if(this.countryId === '') return ''
        return this._.find(this.countries, {id: parseInt(this.countryId)})
      },
    },
  }
</script>

<style scoped>

</style>
